@include media-breakpoint-down(lg) {
  #light-field {
    height: 140px !important;
  }
  .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5,.col-md-6, .col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .container-fluid {
    padding-right: 30px !important;
    padding-left: 20px !important;
  }

  .pb-4 {
    padding-bottom: 10px !important;
  }

  body {
    header {
      height: 160px !important;
      .headerlogo {
        picture {
          img {
            max-height: 160px !important;
          }
        }
      }
    }
    main {
      padding-top: 150px !important;
      .parallax-bilder {
        height: 290px !important;
        min-height: 290px !important;
        picture {
          img {
            width: unset !important;
            min-width: 100% !important;
            position: absolute;
          }
        }
      }
      .parallax-bilder-fixed {
        picture {
          img {
            min-width: 100% !important;
            min-height: 100% !important;
          }
        }
      }
      .tinyMCEbackground-3 {
        padding: 15px;
        p {
          font-size: 15px !important;
        }
      }
      .events {
        display: flex;
        .row {
          .col-md-12 {
            padding-right: 30px !important;
            padding-left: 30px !important;
          }
        }
      }
      hr {
        margin-top: 5px;
      }
      h2 {
        font-size: 30px !important;
      }
    }
    footer {
      width: 100% !important;
      min-height: 400px !important;
      .ilu {
        z-index: 1;
      }
      .grid-container{
        z-index: 999;
      }
    }
  }
}