$primary-color: #004750;
$primary-color-light: #007178;
$primary-color-dark: #004750 !default;
$secondary-color: #157178 !default;
$secondary-color-dark: #004750 !default;
$header--height: 280px;
$header--bg-color: #ddc8a2;
$nav--bg-color: #004750;
$footer--bg-color: rgba(234,91,143, 0.9);
$font-family-base: 'Zilla Slab';
$h1--font-family: 'Oswald';
$h2--font-family: $h1--font-family;
$font-weight-base: 400;
$headings-font-weight: 400;
$font-size-base: 1.1rem !important;

$logocloud-icon--color: #157178;


@import "modules/header.scss";
@import "modules/kontakt_icons";
@import "modules/logocloud";
@import "modules/teaser-halb";

$kontakt-icons--a-color-dark: #006373;
$kontakt-icons--a-color-dark--hover: #43b1b3;

$container-max-widths: (
  xs: 540px,
  sm: 720px,
  md: 960px,
  lg: 1140px,
  xl: 1500px
);

body {
  font-size: 1.1rem !important;
  h1 {
    color: $footer--bg-color;
  }
  h2 {
    color: #43b1b3;
  }
  header {
    position: fixed !important;
    z-index: 999;
    background: rgba(255,255,255, 0.25) !important;
    #dark-field {
      width: 100%;
      height: 80px;
      background-color: rgba(0,99,115, 1.0);
      z-index: 2;
      position: absolute;
    }
    #light-field {
      width: 100%;
      height: 220px;
      background-color: rgba(67,177,179, 1.0);
      z-index: 1;
      position: absolute;
    }
    .navbar-toggler {
      position: fixed;
      top: 20px;
      left: 50px;
      z-index: 9999;
      color: #fff4e0;
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,244,224, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
    }
    .header--icons {
      display: none !important;
    }
    @include media-breakpoint-up(lg) {
      .hidden-lg {
        display: none !important;
      }
    }
    nav {
      margin-top: 12px;
      font-size: 30px;
      font-family: $font-family-base;
      color: #117179;
      background: transparent !important;
      text-transform: uppercase;
      flex-direction: row;
      justify-content: space-between;
      z-index: 999;
      height: 50px !important;
      #navbarSupportedContent {
        height: 100%;
        visibility:hidden;
        opacity:0;
        transition:visibility 0.3s linear,opacity 0.3s linear;
        ul {
          @include media-breakpoint-up(lg) {
            max-width: 1000px;
          }
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            a {
              color: #fff4e0;
              font-size: 18px;
              @include media-breakpoint-up(lg) {
                font-size: 25px !important;
              }
            }
          }
        }
      }
      .collapsing {
        height: 100% !important;
        visibility:visible;
        opacity:1;
      }
      .collapse {
        visibility: visible !important;
        opacity: 1 !important;
      }
      .navbar-nav {
        flex-direction: row !important;
        justify-content: space-evenly;
        height: 100%;
      }
    }
    .headerlogo {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 99;
      picture {
        position: relative;
        img {
          max-height: 300px;
          margin-top: 24px;
        }
      }
    }
  }
  .header--icons {
    display: flex !important;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 999;

    .kontakticons {
      font-size: 30px !important;
      padding-top: 25px !important;
      padding-right: 25px !important;
    }
    a:hover {
      color: #fff4e0 !important;
    }
    a {
      display: flex;
      color: #fff4e0 !important;
      i {
        transform: unset !important;
        padding: 0px 10px 10px;
        width: 40px !important;
        left: unset !important;

        img, svg {
          height: 30px !important;
          vertical-align: top !important;
        }
      }
    }
  }
  main {
    padding-top: 250px;
    background: #ffffff !important;
    z-index: 2;
    .tinyMCE:first-child {
      h1 {
        margin-top: 130px;
      }
    }
    .kontakticons {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      i {
        width: unset !important;
        img,svg {
          height: 100px;
        }
      }
      a {
        color: #006373 !important;
      }
      a:hover {
        color: #43b1b3 !important;
      }
    }
    .tinyMCEbackground-1,
    .tinyMCEbackground-2,
    .tinyMCEbackground-3 {
      height: 100%;
      padding: 30px;
      color: #fff !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      hr{
        width: 100%;
      }
      h2 {
        color: #fff !important;
      }
    }
    .tinyMCEbackground-1 {
      background: rgba(0,99,115, 1.0);

    }
    .tinyMCEbackground-2 {
      background: rgba(67,177,179, 1.0);
    }
    .tinyMCEbackground-3 {
      background: rgba(234,91,143, 1);
    }
    .grid-container {
      z-index: 99;
    }
    .container-fluid {
      padding-right: 30px;
      padding-left: 30px;
    }
    a:hover {
      color: #43b1b3;
    }
  }

  .container-color-1:before,
  .container-color-2:before,
  .container-color-3:before,
  .container-color-4:before {
    font-weight: bold;
    font-size: 300px;
    line-height: 286px;
    margin-left: -10px;
    position: absolute;
  }
  .container-color-1, .container-color-2, .container-color-3 {
    min-height: 300px;
    position: relative;
    display: flex;
    >div {
      position: relative;
      margin-top: 125px;
    }
  }

  .container-color-1 {
    background: #ffffff;
  }
  .container-color-1:before {
    content: "DAS";
    color: #f2f2f1;
  }

  .container-color-2 {
    background: #d7c8a3;
  }
  .container-color-2:before {
    content: "WEM";
    color: #cbbc97;
  }

  .container-color-3 {
    background: #004750;
    color: #ffffff;
    .uppercase {
      color: $header--bg-color !important;
    }
  }
  .container-color-3:before {
    content: "HEY";
    color: #157178;
    line-height: 282px;
  }

  .tinyMCE {
    p {
      line-height: 20px;
    }
  }

  .header--text {
    left: 200px !important;
    top: 80px !important;
    transform: none !important;
    color: $header--bg-color;
  }
  footer {
    text-align: center;
    color: #ffffff;
    padding-top: 15px !important;
    background: $footer--bg-color !important;
    z-index: 1;
    padding-bottom: 30px !important;
    h1, h2, h3, h4, h5, h6 {
      color: #fff !important;
    }
    a {
      color: #ffffff !important;
    }
  }
  .ilu {
    width: 350px;
    height: 286px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    img {
      width: 100%;
      z-index: 99999;
      opacity: 0;
      transform: translateY(50px);
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
  }

  .visible img {
    opacity: 1;
    transform: translateY(0px) !important;
  }

  #table-mauibeach_mailcatcher {
    width: 450px;
    max-width: calc(100% - 10px);
    height: 150px;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    margin-left: 50%;
    transform: translateX(-50%);
    .form-group {
      width: calc(100% - 130px);
      .control-label {
        width: 100%;
        color: rgb(47,47,48);
        text-align: center;
        font-size: 20px;
        font-weight: 700;
      }
      .form-control {
        width: 100%;
        height: 50px;
        padding-left: 20px;
      }
    }
    .btn-primary {
      height: 50px;
      margin-top: 38px;
      margin-left: 10px;
      color: rgb(0,0,0);
      background-color: rgba(190,188, 194, 0);
      border: 1px solid rgb(95, 94, 97);
    }

    .bird {
      position: absolute;
      left: 0px;
      transform: translateX(-100%);
    }
  }
  .events {
    .event {
      display: flex;
      flex-direction: row;
      margin-bottom: 100px;
      .headline {
        font-size: 40px;
        line-height: 27px;
        margin-bottom: 10px;
        color: #006373;
        font-weight: 600;
      }
      .datum {
        font-weight: 600;
        font-size: 30px;
        color: $footer--bg-color;
      }
      .bild {
        display: flex;
        picture {
          position: relative;
          img {
            position: relative;
            width: 100%;
          }
        }
      }
      .btn {
        width: 100% !important;
        background: $footer--bg-color !important;
        color: #ffffff;
        font-size: 30px !important;
        border: 0px;
        padding-top: 25px;
        padding-bottom: 25px;
        border-radius: 0px 0px 10px 10px;

        font-weight: 600 !important;
      }
    }
  }
  .nextevent {
    padding: 20px;
    color: #fff !important;
    justify-content: center;
    display: flex;
    .headline {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
    }
    .date {
      text-align: center;
      font-size: 30px;
      margin-bottom: 20px;
    }
    .ticketbtn {
      text-align: center;
      justify-content: center;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      width: 75%;
      a {
        width: 100%;
        .btn {
          color: #fff !important;
          font-size: 1.1rem !important;
          width: 100%;
          padding-top: 12px !important;
          padding-bottom: 12px !important;
          background: #006373;
          border-color: #006373;
        }
      }
    }
  }
  .nextevent-bg-0 {

  }
  .nextevent-bg-1 {
    background: rgba(0,99,115, 1.0);
    color: #fff;
    .btn-primary {
      background: rgba(67,177,179,1);
    }
  }
  .nextevent-bg-2 {
    background: rgba(67,177,179, 1.0);
  }
  .nextevent-bg-3 {
    background: rgba(234,91,143, 1);
  }
}