$logocloud-icon--color: #ff0000 !default;

.logocloud {
  img, svg {
    min-width: 100px;
    max-width: 100px;
    * {
      fill: $logocloud-icon--color !important;
    }
  }
}