@include media-breakpoint-down(md) {
  body {
    .header--icons {
      display: none !important;
    }
    main {
      .kontakticons {
        display: flex;
        flex-direction: column;
        a {
          margin-bottom: 40px !important;
          i {
            justify-content: center;
            display: flex;
          }
        }
      }
      .tinyMCE {
        padding: 0px 10px !important;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .parallax-bilder {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  footer {
    min-height: 520px !important;
  }

  body {
    header {
      nav {
        .navbar-collapse {
          height: auto !important;
          position: fixed;
          top: 10px !important;
          width: 100% !important;
          background: #006373 !important;
          padding: 20px 0px 20px;
          ul {
            flex-direction: column !important;
            li {
              a {
                font-size: 22px !important;
              }
            }
          }
        }
      }
    }
  }
}